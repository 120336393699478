import PropTypes from 'prop-types'
import React from 'react'

import RaffleListPage from '../components/RaffleListPage'

const LeisurePage = ({ location }) => (
  <RaffleListPage
    industry={location.state ? location.state.industry : 'leisure'}
  />
)

LeisurePage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default LeisurePage
